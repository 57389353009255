.wrapper{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(237, 234, 232, var(--tw-border-opacity));
  border-bottom-width:1px;
  z-index:50;
  transition-property:background-color, border-color, color, fill, stroke;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  position:fixed;
  width:100%
}

.wrapper .logoWhite{
  display:none
}

.wrapper.variantHomepage{
  border-bottom-width:0px;
  --tw-shadow:0px 12px 12px rgba(78, 47, 39, 0.11);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.wrapper.variantHomepage.variantTransparent{
  background-color:transparent;
  box-shadow:none
}

.wrapper.variantHomepage.variantTransparent .logoRed{
  display:none
}

.wrapper.variantHomepage.variantTransparent .logoWhite{
  display:block
}

@media (min-width: 1024px){
  .wrapper.variantHomepage.variantTransparent{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .wrapper.variantHomepage.variantTransparent .logoRed{
    display:block
  }

  .wrapper.variantHomepage.variantTransparent .logoWhite{
    display:none
  }
}

.logo{
  border-radius:0.375rem;
  height:3.25rem;
  line-height:1;
  margin-left:2.5rem;
  margin-right:2.5rem;
  overflow:hidden
}

@media (min-width: 768px){
  .logo{
    height:auto
  }
}

.logo{
  display:flex;
  align-items:center
}

.navigation{
  padding-top:4rem;
  padding-bottom:4rem;
  display:flex;
  flex-direction:column;
  align-items:center;
  position:relative;
  height:100%
}

@media (min-width: 1024px){
  .navigation{
    padding-top:3rem;
    padding-bottom:3rem;
    flex-direction:row;
    justify-content:space-between
  }
}

.navigation .navList{
  margin-bottom:2.5rem;
  text-align:center
}

@media (min-width: 1024px){
  .navigation .navList{
    display:grid;
    margin-bottom:0px;
    -moz-column-gap:5rem;
         column-gap:5rem;
    row-gap:1.5rem;
    grid-auto-flow:column;
    grid-template-columns:repeat(3, minmax(0, 1fr));
    grid-template-rows:repeat(3, minmax(0, 1fr));
    text-align:left
  }
}

.navigation .navItem:not(:last-child){
  margin-bottom:1.25rem
}

@media (min-width: 1024px){
  .navigation .navItem:not(:last-child){
    margin-bottom:0px
  }
}

.navigation .navLink{
  border-radius:0.375rem;
  font-size:1.25rem;
  line-height:1.75rem;
  --tw-text-opacity:1;
  color:rgba(67, 47, 42, var(--tw-text-opacity));
  transition-property:background-color, border-color, color, fill, stroke;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

@media (min-width: 1024px){
  .navigation .navLink{
    font-size:1rem;
    line-height:1.5rem
  }
}

.navigation .navLink{
  position:relative
}

.navigation .navLink:hover{
  --tw-text-opacity:1;
  color:rgba(255, 62, 28, var(--tw-text-opacity))
}

.navigation .navLink.active{
  padding-left:1rem;
  --tw-text-opacity:1;
  color:rgba(255, 62, 28, var(--tw-text-opacity));
  text-decoration:underline
}

.navigation .navLink.active::before{
  --tw-bg-opacity:1;
  background-color:rgba(255, 62, 28, var(--tw-bg-opacity));
  border-radius:9999px;
  height:0.625rem;
  width:0.625rem;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y:-50%;
  content:"";
  position:absolute;
  top:50%;
  left:0
}

.illustration{
  top:20rem;
  right:-3.5rem;
  position:absolute
}

@media (min-width: 1024px){
  .illustration{
    top:33.333333%;
    right:33.333333%
  }
}

.searchShortcut{
  --tw-bg-opacity:1;
  background-color:rgba(246, 244, 244, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(237, 234, 232, var(--tw-border-opacity));
  border-radius:9999px;
  border-width:1px;
  height:2.5rem;
  padding-left:1rem;
  --tw-text-opacity:1;
  color:rgba(161, 150, 147, var(--tw-text-opacity));
  width:15rem;
  transition-property:all;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  display:flex;
  align-items:center;
  position:relative
}

.searchShortcut:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 62, 28, var(--tw-border-opacity));
  --tw-text-opacity:1;
  color:rgba(67, 47, 42, var(--tw-text-opacity))
}

.searchShortcut:focus{
  --tw-border-opacity:1;
  border-color:rgba(237, 234, 232, var(--tw-border-opacity));
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(214, 211, 210, var(--tw-ring-opacity));
  outline:none
}

.searchShortcut .searchShortcutButton{
  --tw-bg-opacity:1;
  background-color:rgba(255, 62, 28, var(--tw-bg-opacity));
  border-radius:9999px;
  height:2rem;
  right:0.25rem;
  width:2rem;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y:-50%;
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  top:50%
}

.searchShortcut .searchShortcutButton:disabled{
  opacity:0.35;
  cursor:pointer
}

.userDropdownButton{
  border-radius:0.375rem;
  transition-property:all;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  display:flex;
  align-items:center
}

.userDropdownButton:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width:2px;
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(214, 211, 210, var(--tw-ring-opacity));
  outline:none
}

.userDropdownButton:hover{
  opacity:0.65
}

.userDropdownPopper{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(229, 226, 225, var(--tw-border-opacity));
  border-radius:0.375rem;
  border-width:1px;
  padding:1rem;
  --tw-shadow:0px 4px 30px rgba(78, 47, 39, 0.16);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width:9rem
}

.userDropdownArrow{
  right:1rem;
  position:absolute;
  top:-14px;
  width:0;
  height:0;
  border-left:14px solid transparent;
  border-right:14px solid transparent;
  border-bottom:14px solid #E5E2E1
}

.userDropdownArrow::after{
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x:-50%;
  content:"";
  position:absolute;
  top:1px;
  left:0;
  width:0;
  height:0;
  border-left:13px solid transparent;
  border-right:13px solid transparent;
  border-bottom:13px solid #FFFFFF
}

.checkoutButton{
  --tw-bg-opacity:1;
  background-color:rgba(255, 62, 28, var(--tw-bg-opacity));
  border-radius:9999px;
  font-weight:600;
  padding-left:1.25rem;
  padding-right:1.25rem;
  padding-top:0.625rem;
  padding-bottom:0.625rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  transition-property:all;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  display:flex;
  align-items:center
}

.checkoutButton:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width:2px;
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(214, 211, 210, var(--tw-ring-opacity));
  outline:none
}

.checkoutButton:hover{
  opacity:0.65
}