.wrapper {
  @apply z-50 bg-white border-b border-gray transition-colors;

  position: fixed;
  width: 100%;

  .logoWhite {
    display: none;
  }

  &.variantHomepage {
    @apply shadow-bottom-lg border-b-0;

    &.variantTransparent {
      @apply bg-transparent;

      box-shadow: none;

      .logoRed {
        display: none;
      }

      .logoWhite {
        display: block;
      }

      @screen lg {
        @apply bg-white;

        .logoRed {
          display: block;
        }

        .logoWhite {
          display: none;
        }
      }
    }
  }
}

.logo {
  @apply h-13 mx-10 rounded-md overflow-hidden leading-none md:h-auto;

  display: flex;
  align-items: center;
}

.navigation {
  @apply py-16;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;

  @screen lg {
    @apply py-12;

    flex-direction: row;
    justify-content: space-between;
  }

  .navList {
    @apply mb-10;

    text-align: center;

    @screen lg {
      @apply grid grid-flow-col grid-rows-3 grid-cols-3 gap-x-20 gap-y-6 mb-0;

      text-align: left;
    }
  }

  .navItem {
    &:not(:last-child) {
      @apply mb-5 lg:mb-0;
    }
  }

  .navLink {
    @apply text-xl text-brown transition-colors rounded-md lg:text-base;

    position: relative;

    &:hover {
      @apply text-red;
    }

    &.active {
      @apply pl-4 text-red underline;

      &::before {
        @apply transform -translate-y-1/2 w-2.5 h-2.5 rounded-full bg-red;

        content: '';
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }
}

.illustration {
  @apply -right-14 top-80;

  position: absolute;

  @screen lg {
    @apply right-1/3 top-1/3;
  }
}

.searchShortcut {
  @apply w-60 h-10 pl-4 rounded-full bg-gray-light border border-gray text-brown-light transition-all;

  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    @apply border-red text-brown;
  }

  &:focus {
    @apply ring-2 ring-gray-darker border-gray;

    outline: none;
  }

  .searchShortcutButton {
    @apply transform -translate-y-1/2 right-1 w-8 h-8 rounded-full bg-red;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;

    &:disabled {
      @apply opacity-disabled;

      cursor: pointer;
    }
  }
}

.userDropdownButton {
  @apply rounded-md transition-all;

  display: flex;
  align-items: center;

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }

  &:hover {
    @apply opacity-hover;
  }
}

.userDropdownPopper {
  @apply w-36 p-4 bg-white border border-gray-dark shadow-lg rounded-md;
}

.userDropdownArrow {
  @apply right-4;

  position: absolute;
  top: -14px;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid theme('colors.gray.dark');

  &::after {
    @apply transform -translate-x-1/2;

    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid theme('colors.white');
  }
}

.checkoutButton {
  @apply px-5 py-2.5 bg-red text-white font-semibold rounded-full transition-all;

  display: flex;
  align-items: center;

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }

  &:hover {
    @apply opacity-hover;
  }
}
