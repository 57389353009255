.error {
  display: flex;
  position: relative;
  height: calc(100vh - 7rem);

  @apply pt-16 pb-20 px-4;

  @screen xl {
    @apply pb-0;
  }

  &.notFound {
    @screen xl {
      max-width: 42rem;

      @apply pb-40;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;

  .button {
    @apply h-10 px-5;

    @screen sm {
      @apply px-7;
    }
  }
}

.illustrations {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 4rem;
  z-index: -1;

  @apply w-full px-4;
}

.singleIllustration {
  max-width: 65%;

  @screen xl {
    position: relative;
    left: 6rem;
    bottom: 1rem;
  }
}

.container {
  max-width: 64rem;
  display: flex;
  justify-content: center;

  @apply mx-auto;
}
