.base {
  @apply max-w-sm mx-auto rounded-md text-left;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: content-box;

  @screen lg {
    @apply max-w-5xl h-20 rounded-full;

    flex-direction: row;
  }

  .main {
    @apply bg-white rounded-md;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @screen lg {
      @apply h-20 w-full pl-8 pr-20 rounded-full;

      flex-direction: row;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .textInputWrapper {
    @apply w-full px-4;

    @screen lg {
      @apply px-0;

      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  .textInputLabel {
    @apply text-sm mt-1;

    display: none;

    @screen lg {
      display: block;
    }
  }

  .textInput {
    @apply w-full h-14 placeholder-brown-light;

    &::placeholder {
      @apply transition-opacity;
    }

    &:focus {
      outline: none;

      &::placeholder {
        @apply opacity-0;
      }
    }

    @screen lg {
      @apply h-8 font-semibold;
    }
  }

  .buttonDesktop {
    display: none;

    @screen lg {
      @apply transform -translate-y-1/2 right-3 w-14 h-14 rounded-full bg-red;

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
    }
  }

  &.default {
    @apply border border-gray-dark;

    .main {
      @apply w-full;
    }
  }

  &.homepage {
    @apply bg-red-light shadow-sm;
  }
}
