.wrapperSimple {
  @apply my-8 text-brown-light;

  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @screen xl {
    display: flex;
  }

  &.homepage {
    display: none;
  }
}

.wrapperExtended {
  @apply my-14 text-brown-light;

  flex-direction: column;
  justify-content: space-between;

  @screen lg {
    flex-direction: row;
  }

  @screen xl {
    display: none;
  }

  &.homepage {
    display: flex;
  }

  &.checkout {
    flex-direction: column;
    align-items: center;
  }

  .paragraph {
    @apply mb-4 text-xs font-medium leading-relaxed;

    &:last-child {
      @apply mb-0;
    }

    @screen lg {
      @apply max-w-sm;
    }

    @screen xl {
      @apply max-w-xl;
    }
  }

  .menu {
    @apply grid grid-rows-4 grid-cols-2 gap-3 m-0;

    > li > a {
      @apply leading-none box-border;
    }
  }

  .cta {
    @apply mr-0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.scrollUpButton {
  @apply rounded-full;

  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
}
